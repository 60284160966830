import React, { Fragment } from 'react'
// Customizable Area Start
//@ts-ignore
import { AppBar, Toolbar, Popover, Switch, Backdrop, ClickAwayListener, MenuItem, ListItemIcon, MenuList, Grid, ListItemText } from "@material-ui/core";
import Box from '@material-ui/core/Box'
import { IrisLogo, Flag, DownArrow, arrow, arabicFlag } from './assets';
//@ts-ignore
import MenuIcon from "@material-ui/icons/Menu";
import "../../../web/src/App.css"
import { configJSON } from './/NavigationBarController.web';
import TermsAndConditions3 from '../../TermsAndConditions3/src/TermsAndConditions3.web';
import { Link, withRouter } from 'react-router-dom';
import Submit from '../../../components/src/dialog/Submit';
import SearchInput from '../../ElasticSearch/src/SearchInput.web';
import Customform3 from '../../customform3/src/Customform3.web';
import NavigationBarController from './NavigationBarController.web';
import CustomPopover from '../../../components/src/customPopover/CustomPopover';

const { translate } = configJSON
export const style: {
  [key: string]: {
    [key: string]: string | number
  }
} = {
  text: {
    color: '#676766',
    fontSize: '0.75rem'
  },
  select_text: {
    color: 'black',
    fontSize: '0.75rem'
  },
  menuItem: {},
  select_menuItem: {
    borderLeft: '3px solid #603',
    background: '#f6f6f6'
  },
  menuColor: {
    color: '#646464'
  },
  select_menuColor: {
    color: '#363636',
    fontWeight: 500,
    fontSize: '0.875rem'
  },
  more_box: {
    height: '70px'
  },
  navItem: {
    height: '58px',
    position: "relative"
  },
  flag: {
    width: '18px',
    height: '18px',
    borderRadius: '50%'
  }
}

export const TermsAndConditionIcon = ({ color }: { color: string }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clip-path="url(#clip0_416_1094)">
        <path id="Vector" d="M5.25 3V1.5H12.75V3H15.0049C15.4164 3 15.75 3.33371 15.75 3.74505V15.7549C15.75 16.1664 15.4163 16.5 15.0049 16.5H2.99505C2.58357 16.5 2.25 16.1663 2.25 15.7549V3.74505C2.25 3.33357 2.58371 3 2.99505 3H5.25ZM5.25 4.5H3.75V15H14.25V4.5H12.75V6H5.25V4.5ZM6.75 3V4.5H11.25V3H6.75Z" fill={`${color}`} />
      </g>
      <defs>
        <clipPath id="clip0_416_1094">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const SettingIcon = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_1179_939)">
        <path d="M9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5ZM7.28252 14.7506C6.56057 13.2194 6.11799 11.5307 6.02048 9.75H3.04642C3.3435 12.1324 5.03729 14.081 7.28252 14.7506ZM7.52302 9.75C7.63582 11.5791 8.15835 13.2973 9 14.814C9.84165 13.2973 10.3642 11.5791 10.477 9.75H7.52302ZM14.9536 9.75H11.9795C11.882 11.5307 11.4395 13.2194 10.7175 14.7506C12.9627 14.081 14.6565 12.1324 14.9536 9.75ZM3.04642 8.25H6.02048C6.11799 6.46933 6.56057 4.78055 7.28252 3.24942C5.03729 3.919 3.3435 5.86762 3.04642 8.25ZM7.52302 8.25H10.477C10.3642 6.42092 9.84165 4.70269 9 3.18599C8.15835 4.70269 7.63582 6.42092 7.52302 8.25ZM10.7175 3.24942C11.4395 4.78055 11.882 6.46933 11.9795 8.25H14.9536C14.6565 5.86762 12.9627 3.919 10.7175 3.24942Z" fill={`${color}`} />
      </g>
      <defs>
        <clipPath id="clip0_1179_939">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export class NavigationBar extends NavigationBarController {
  navFooterData = () => {
    const { categories, openPopover } = this.state
    const { history } = this.props
    const lang = localStorage.getItem('lang') ?? 'en';
    return (
      <div>
        <div className="NavBarScondContainer " >
          <CustomPopover anchorEl={openPopover[configJSON.about_us]}
            onMenuItemClick={this.handleCategory}
            handleClose={() => this.handleOpenPopover(configJSON.about_us, null)}
            data_testId={`${configJSON.about_us}-popover`}
            menuItems={categories[configJSON.about_us]} >
            <div className="MoreContainer" style={style.navItem} onClick={e => this.handleOpenPopover(configJSON.about_us, e.currentTarget)} data-testid={configJSON.about_us}>
              <p className="MenuText">{translate.ABOUT_US[lang]}</p>
              <img src={DownArrow} alt="DownArrow" className='down-arrow'></img>
              <img src={arrow} alt='arrow' style={{ position: 'absolute', bottom: '0', left: "50%", zIndex: 2, visibility: openPopover[configJSON.about_us] ? 'visible' : 'hidden' }} />
            </div>
          </CustomPopover>

          <CustomPopover anchorEl={openPopover[configJSON.franchise]}
            onMenuItemClick={this.handleCategory}
            handleClose={() => this.handleOpenPopover(configJSON.franchise, null)}
            data_testId={`${configJSON.franchise}-popover`}
            menuItems={categories[configJSON.franchise]} >
            <div className="MoreContainer" style={style.navItem} onClick={e => this.handleOpenPopover(configJSON.franchise, e.currentTarget)} data-testid={configJSON.franchise}>
              <p className="MenuText" >{translate.IRIS_FRANCHISE[lang]}</p>
              <img src={DownArrow} alt="DownArrow" className='down-arrow'></img>
              <img src={arrow} alt='arrow' style={{ position: 'absolute', bottom: '0px', zIndex: 2, left: "50%", visibility: openPopover[configJSON.franchise] ? 'visible' : 'hidden' }} />
            </div>
          </CustomPopover>
          <div className="MoreContainer" data-testid={configJSON.irisDevelopment} onClick={() => this.customFormCallBack('Development')}>
            <p className="MenuText">{translate.IRIS_DEVELOPMENT[lang]}</p>
          </div>
          <div className="MoreContainer" data-testid={configJSON.irisHolidays} onClick={this.handleGoToHoliday.bind(this)}>
            <p className="MenuText">{translate.IRIS_HOLIDAY[lang]}</p>
          </div>
          <div className="MoreContainer" data-testid={configJSON.our_brands} onClick={this.ourbrandNavigate}>
            <p className="MenuText">{translate.OUR_BRANDS[lang]}</p>
          </div>

          <CustomPopover anchorEl={openPopover[configJSON.hotels]}
            onMenuItemClick={this.handleCategory}
            handleClose={this.handleOpenPopover.bind(this, configJSON.hotels, null)}
            data_testId={`${configJSON.hotels}-popover`}
            menuItems={categories[configJSON.hotels]} >
            <div className="MoreContainer" style={style.navItem} onClick={e => this.handleOpenPopover(configJSON.hotels, e.currentTarget)} data-testid={configJSON.hotels}>
              <p className="MenuText" >{translate.HOTELS[lang]}</p>
              <img src={DownArrow} alt="DownArrow" className='down-arrow'></img>
              <img src={arrow} alt='arrow' style={{ position: 'absolute', bottom: '0px', zIndex: 2, left: "50%", visibility: openPopover[configJSON.hotels] ? 'visible' : 'hidden' }} />
            </div>
          </CustomPopover>
          <div className="MoreContainer" data-testid={configJSON.wellness_health} onClick={this.navigateToWellnessAndHealth.bind(this)} >
            <p className="MenuText">{translate.WELLNESS_HEALTH[lang]}</p>
          </div>
          <div className="MoreContainer"
            onClick={this.handleGoToPromotions.bind(this)}
            data-testid={configJSON.promotions}>
            <p className="MenuText">{translate.PROMOTIONS[lang]}</p>
          </div>
          <div className="MoreContainer" data-testid={configJSON.contactUs} onClick={this.handleGoToContactUs.bind(this)}>
            <p className="MenuText">{translate.CONTACT_US[lang]}</p>
          </div>
        </div>
      </div>
    )
  }

  mobileNavbarData = () => {
    const { categories, openPopover } = this.state
    const lang = localStorage.getItem('lang') ?? 'en';
    return (
      <div>
        <div className="NavBarSecondContainerMobile " >
          <CustomPopover anchorEl={openPopover[configJSON.about_us]}
            onMenuItemClick={this.handleCategory}
            handleClose={this.handleOpenPopover.bind(this, configJSON.about_us, null)}
            data_testId={`${configJSON.about_us}-popover`}
            menuItems={categories[configJSON.about_us]} >
            <div className="MoreContainerMobile" style={style.navItem} onClick={e => this.handleOpenPopover(configJSON.about_us, e.currentTarget)} data-testid={configJSON.about_us}>
              <p className="MenuText">{translate.ABOUT_US[lang]}</p>
              <img
                src={DownArrow}
                alt="DownArrow"
                className='down-arrow'
              />
              <img
                src={arrow}
                alt='arrow'
                style={{
                  position: 'absolute',
                  bottom: '0', left: "50%", zIndex: 2,
                  visibility: openPopover[configJSON.about_us] ? 'visible' : 'hidden'
                }} />
            </div>
          </CustomPopover>

          <CustomPopover anchorEl={openPopover[configJSON.franchise]} onMenuItemClick={this.handleCategory} handleClose={this.handleOpenPopover.bind(this, configJSON.franchise, null)} data_testId={`${configJSON.franchise}-popover`} menuItems={categories[configJSON.franchise]} >
            <div className="MoreContainerMobile" style={style.navItem} onClick={e => this.handleOpenPopover(configJSON.franchise, e.currentTarget)} data-testid={configJSON.franchise}>
              <p className="MenuText" >{translate.IRIS_FRANCHISE[lang]}</p>
              <img src={DownArrow} alt="DownArrow" className='down-arrow'></img>
              <img src={arrow} style={{ left: "50%", visibility: openPopover[configJSON.franchise] ? 'visible' : 'hidden', position: 'absolute', bottom: '0px', zIndex: 2, }} alt='arrow' />
            </div>
          </CustomPopover>
          <div className="MoreContainerMobile" data-testid={configJSON.irisDevelopment} onClick={this.customFormCallBack.bind(this, 'Development')}>
            <p className="MenuText">{translate.IRIS_DEVELOPMENT[lang]}</p>
          </div>
          <div className="MoreContainerMobile" data-testid={configJSON.irisHolidays} onClick={this.handleGoToHoliday.bind(this)}>
            <p className="MenuText">{translate.IRIS_HOLIDAY[lang]}</p>
          </div>
          <div className="MoreContainerMobile" data-testid={configJSON.our_brands} onClick={this.ourbrandNavigate}>
            <p className="MenuText">{translate.OUR_BRANDS[lang]}</p>
          </div>

          <CustomPopover anchorEl={openPopover[configJSON.hotels]}
            onMenuItemClick={this.handleCategory}
            handleClose={this.handleOpenPopover.bind(this, configJSON.hotels, null)}
            data_testId={`${configJSON.hotels}-popover`}
            menuItems={categories[configJSON.hotels]} >
            <div className="MoreContainerMobile" style={style.navItem} onClick={e => this.handleOpenPopover(configJSON.hotels, e.currentTarget)} data-testid={configJSON.hotels}>
              <p className="MenuText" >{translate.HOTELS[lang]}</p>
              <img src={DownArrow} alt="DownArrow" className='down-arrow'></img>
              <img src={arrow} alt='arrow' style={{ position: 'absolute', bottom: '0px', zIndex: 2, left: "50%", visibility: openPopover[configJSON.hotels] ? 'visible' : 'hidden' }} />
            </div>
          </CustomPopover>
          <div className="MoreContainerMobile" data-testid={configJSON.wellness_health} onClick={this.navigateToWellnessAndHealth.bind(this)} >
            <p className="MenuText">{translate.WELLNESS_HEALTH[lang]}</p>
          </div>
          <div className="MoreContainerMobile"
            onClick={this.handleGoToPromotions.bind(this)}
            data-testid={configJSON.promotions}>
            <p className="MenuText">{translate.PROMOTIONS[lang]}</p>
          </div>
          <div className="MoreContainerMobile" data-testid={configJSON.contactUs} onClick={this.handleGoToContactUs.bind(this)}>
            <p className="MenuText">{translate.CONTACT_US[lang]}</p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { openPopover, switchLanguage, selectedMore, openFormDialog, submitDialog } = this.state
    const textPrefixCss1 = !switchLanguage ? "select_" : ""
    const textPrefixCss2 = switchLanguage ? "select_" : ""
    const menuPrefixCss1 = selectedMore === "terms" ? "select_" : ""
    const menuPrefixCss2 = selectedMore === "settings" ? "select_" : ""
    const lang = localStorage.getItem('lang') ?? 'en'
    const actFlag = lang === 'en' ? Flag : arabicFlag
    return (
      <Fragment>
        <AppBar position="relative" className="NavBarDiv">
          <Toolbar className="NavBarInnerContainer">
            <Link className="LogoDiv" to='/'>
              <img src={IrisLogo} alt="logo" className="IrisLogo" ></img>
            </Link>
            <Box className="RightMenuDiv" alignItems={"center"}>
              <Box className="LogoDiv1">
                <img src={IrisLogo} alt="logo" className="IrisLogo"></img>
              </Box>
              <Box className="searchInput">
                <SearchInput />
              </Box>
              <Box className="contentContainer"> 
                <ClickAwayListener onClickAway={() => {
                  this.handleSelectedMoreMenu(null)
                  this.handleOpenPopover(configJSON.more, null)
                }}>
                  <>
                    <TermsAndConditions3 />
                    <Box className="MoreContainer" style={style.more_box} onClick={(e) => this.handleOpenPopover(configJSON.more, e.currentTarget)} data-testid={configJSON.more}>
                      <MenuIcon className="MoreIcon" />
                      <p className="MoreText">{translate.more[lang]}</p>
                      <img src={arrow} alt='arrow' style={{ position: 'absolute', bottom: '9px', zIndex: 2, visibility: openPopover[configJSON.more] ? 'visible' : 'hidden' }} />
                    </Box>
                    <Backdrop open={!!openPopover[configJSON.more]} classes={{ root: 'backdrop-root' }}>
                      <Popover open={!!openPopover[configJSON.more]} anchorEl={openPopover[configJSON.more]} onClose={() => {
                        this.handleSelectedMoreMenu(null)
                        this.handleOpenPopover(configJSON.more, null)
                      }} anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom'
                      }}
                        transformOrigin={{
                          horizontal: 'center',
                          vertical: 'top'
                        }}
                        PaperProps={{
                          style: {
                            padding: '0 6px',
                            borderRadius: '5px',
                            width: '230px'
                          }
                        }}
                        data-testid={`${configJSON.more}-popover`}
                      >
                        <MenuList>
                          <MenuItem disableGutters onClick={() => this.handleSelectedMoreMenu('terms')} classes={{
                            root: 'menulist-item'
                          }} style={style[`${menuPrefixCss1}menuItem`]}>
                            <ListItemIcon style={{ minWidth: 'max-content', marginLeft: '17px' }}>
                              <TermsAndConditionIcon color={style[`${menuPrefixCss1}menuColor`].color as string} />
                            </ListItemIcon>
                            <ListItemText className='menuColor' disableTypography style={{ marginLeft: '8px', ...style[`${menuPrefixCss1}menuColor`] }}>{translate.TERMS_CONDITION[lang]}</ListItemText>
                          </MenuItem>
                          <MenuItem disableGutters onClick={() => this.handleSelectedMoreMenu('settings')} classes={{
                            root: 'menulist-item'
                          }} style={{ display: 'flex', flexDirection: 'column', ...style[`${menuPrefixCss2}menuItem`] }}>
                            <Grid container alignItems='center' direction='row'>
                              <ListItemIcon style={{ minWidth: 'max-content', marginLeft: '17px' }}>
                                <SettingIcon color={style[`${menuPrefixCss2}menuColor`].color as string} />
                              </ListItemIcon>
                              <ListItemText className='menuColor' disableTypography style={{ marginLeft: '8px', ...style[`${menuPrefixCss2}menuColor`] }}>{translate.Select_language[lang]}</ListItemText>
                            </Grid>
                            {
                              selectedMore === "settings" && (
                                <Box display={'flex'} alignItems={'center'}>
                                  <span style={style[`${textPrefixCss1}text`]}>{translate.en[lang]}</span><Switch onChange={this.handleSwitchLanguage} data-testid={"switch-language"} checked={switchLanguage} classes={{
                                    thumb: 'switch-button'
                                  }} /><span style={style[`${textPrefixCss2}text`]}>{translate.ar[lang]}</span>
                                </Box>
                              )
                            }
                          </MenuItem>
                        </MenuList>
                      </Popover>
                    </Backdrop>
                  </>
                </ClickAwayListener>
                <ClickAwayListener onClickAway={() => this.handleOpenPopover(configJSON.language, null)}>
                  <>
                    <Box style={style.more_box} display={'flex'} alignItems={'center'} onClick={(e) => this.handleOpenPopover(configJSON.language, e.currentTarget)} data-testid={configJSON.language}>
                      <Box className="Language_Button_Div">
                        <img src={actFlag} alt="Flag" style={style.flag} />
                        <p className="LanguageText">{switchLanguage ? 'AR' : 'EN'}</p>
                        <img src={DownArrow} alt="DownArrow"></img>
                        <img src={arrow} alt='arrow' style={{ position: 'absolute', bottom: '9px', zIndex: 2, visibility: openPopover[configJSON.language] ? 'visible' : 'hidden' }} />
                      </Box>
                    </Box>
                    <Backdrop open={!!openPopover[configJSON.language]} classes={{ root: 'backdrop-root' }}>
                      <Popover open={!!openPopover[configJSON.language]} anchorEl={openPopover[configJSON.language]} onClose={() => {
                        this.handleOpenPopover(configJSON.language, null)
                      }} anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom'
                      }}
                        transformOrigin={{
                          horizontal: 'center',
                          vertical: 'top'
                        }}
                        PaperProps={{
                          style: {
                            padding: '12px',
                            borderRadius: '5px',
                          }
                        }}
                        data-testid={`${configJSON.language}-popover`}
                      >
                        <p style={style.text}>{translate.Select_language[lang]}</p>
                        <Box display={'flex'} alignItems={'center'}>
                          <span style={style[`${textPrefixCss1}text`]}>{translate.en[lang]}</span>
                          <Switch checked={switchLanguage} data-tesid={"switch-language"} onChange={this.handleSwitchLanguage} classes={{
                            thumb: 'switch-button'
                          }} />
                          <span style={style[`${textPrefixCss2}text`]}>{translate.ar[lang]}</span>
                        </Box>
                      </Popover>
                    </Backdrop>
                  </>
                </ClickAwayListener>
                <Box className="BookNow" data-testid={"book_now"} onClick={this.handleBookNow}>{translate.book[lang]}</Box>

              </Box>
            </Box>
          </Toolbar>

          <hr className="hr" />
          <MenuIcon className="MenuIcon950" onClick={this.handleOpenMenuMobile} />
          {this.state.isMenuBarMobileOpen && this.mobileNavbarData()}
          {this.navFooterData()}
        </AppBar>
        <Customform3 formType={openFormDialog} handleClose={this.closeCustomForm} handleSubmit={this.handleFormSubmit} />
        <Submit open={submitDialog} handleClose={this.handleSubmitDialog} handleMore={this.handleSubmitDialog} />
      </Fragment>
    );
  }
}

export default withRouter(NavigationBar);

// Customizable Area End